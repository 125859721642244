<template>
    <section class="position-relative">
        <div class="justify-content-end" :class="$route.name != 'admin.tiendas.produccion.lista' ? 'w-100 d-flex px-2 py-3 bg-white z-1 br-t-12' : 'row mx-0'">
            <div v-if="$route.name == 'admin.tiendas.produccion.mapa'" class="col-auto d-flex justify-content-end">
                <div class="bg-white border br-20 px-2 py-1 z-1 d-middle justify-content-end pr-3" style="right:80px;top:0px;">
                    <el-checkbox v-model="cedis" class="check-red">
                        Mostrar cedis
                    </el-checkbox>
                </div>
                <div class="bg-white border br-20 px-2 py-1 z-1 d-middle justify-content-end pr-3" style="right:80px;top:0px;">
                    <el-checkbox v-model="tiendas" class="check-red mx-2" />
                    <el-select v-model="idTienda" class="w-100" size="mini" filterable>
                        <el-option
                        v-for="item in arrTiendas"
                        :key="item.id"
                        :label="item.propietario_nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="bg-white border br-20 px-2 py-1 z-1 d-middle justify-content-end pr-3" style="right:80px;top:0px;">
                    <el-checkbox v-model="clientes" class="check-red">
                        Mostrar clientes
                    </el-checkbox>
                </div>
            </div>
            <!-- 
                Sección para la geolocalización
             -->
            <div v-if="$route.name == 'admin.tiendas.produccion.geolocalizacion'" class="col d-flex justify-content-end">
                <div class="bg-white br-20 px-2 py-1 z-1 d-middle justify-content-end pr-3 f-16 f-600 text-general" style="right:80px;top:0px;">
                    Geolocalización
                </div>
                <div class="bg-white br-20 px-2 py-1 z-1 d-middle justify-content-end pr-3" style="right:80px;top:0px;">
                    <el-select v-model="geoTienda" class="w-100" size="small" filterable>
                        <el-option
                        v-for="item in geoLeecheros"
                        :key="item.id"
                        :label="item.propietario_nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="bg-white br-20 px-2 py-1 z-1 d-middle justify-content-end pr-3" style="right:80px;top:0px;">
                    <el-date-picker
                    v-model="geoFecha"
                    type="date"
                    size="small"
                    placeholder="Fecha"
                    value-format="yyyy-MM-dd"
                    format="dd MMM yyyy"
                    :clearable="false"
                    :picker-options="pickerOptions"
                    />
                </div>
                <div class="bg-white br-20 px-2 py-1 z-1 d-middle justify-content-end pr-3" style="right:80px;top:0px;">
                    <el-time-picker
                    v-model="geoHoras"
                    is-range
                    size="small"
                    value-format="HH:mm"
                    format="hh:mm a"
                    range-separator="a"
                    start-placeholder="Hora inicio"
                    end-placeholder="Hora fin"
                    :clearable="false"
                    />
                </div>
                <button class="btn btn-general bg-general d-middle-center text-white p-1 px-5 br-8 mx-2 mt-1" @click="geoGenerar()">
                    Generar ubicaciones
                </button>
            </div>
            <div v-if="$route.name != 'admin.tiendas.produccion.lista'" class="br-8 d-middle z-1 ml-2">
                <router-link 
                :to="{ name: 'admin.tiendas.produccion.lista' }" 
                active-class="active border-black"
                class="d-middle-center border-black text-general" 
                style="width:32px;height:32px;border-radius:8px 0px 0px 8px"
                >
                    <i class="icon-menu f-20 my-n1" />
                </router-link>
                <router-link 
                :to="{ name: 'admin.tiendas.produccion.mapa' }" 
                active-class="active border-black"
                class="d-middle-center border-black text-general " 
                style="width:32px;height:32px;"
                >
                    <i class="icon-location f-20 my-n1" />
                </router-link>
                <router-link 
                :to="{ name: 'admin.tiendas.produccion.geolocalizacion' }" 
                active-class="active border-black"
                class="d-middle-center border-black text-general " 
                style="width:32px;height:32px;border-radius:0px 8px 8px 0px"
                >
                    <i class="icon-route f-20 my-n1" />
                </router-link>
            </div>
        </div>
        <router-view />
    </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    middleware:['auth'],
    data(){
        return {
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() >= new Date()
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            geoLeecheros: 'produccion/geolocalizacion/leecheros',
        }),
        cedis: {
            get(){
                return this.$store.getters['produccion/mapa/getCedis']
            },
            async set(value){
                this.$store.commit('produccion/mapa/setCedis', value)
                await this.$store.dispatch('produccion/mapa/getDatosMapa')
                this.$store.commit('produccion/mapa/setRefresh', true)
            }
        },
        clientes: {
            get(){
                return this.$store.getters['produccion/mapa/getClientes']
            },
            async set(value){
                this.$store.commit('produccion/mapa/setClientes', value)
                await this.$store.dispatch('produccion/mapa/getDatosMapa')
                this.$store.commit('produccion/mapa/setRefresh', true)
            }
        },
        tiendas: {
            get(){
                return this.$store.getters['produccion/mapa/getTiendas']
            },
            async set(value){
                this.$store.commit('produccion/mapa/setTiendas', value)
                await this.$store.dispatch('produccion/mapa/getDatosMapa')
                this.$store.commit('produccion/mapa/setRefresh', true)
            }
        },
        idTienda: {
            get(){
                return this.$store.getters['produccion/mapa/getIdTienda']
            },
            async set(value){
                this.$store.commit('produccion/mapa/setIdTienda', value)
                await this.$store.dispatch('produccion/mapa/getDatosMapa')
                this.$store.commit('produccion/mapa/setRefresh', true)
            }
        },
        geoTienda: {
            get(){
                return this.$store.getters['produccion/geolocalizacion/geoTienda']
            },
            async set(value){
                this.$store.commit('produccion/geolocalizacion/setGeoTienda', value)
            }
        },
        geoFecha: {
            get(){
                return this.$store.getters['produccion/geolocalizacion/fecha']
            },
            async set(value){
                this.$store.commit('produccion/geolocalizacion/setFecha', value)
            }
        },
        geoHoras: {
            get(){
                return this.$store.getters['produccion/geolocalizacion/horas']
            },
            async set(value){
                this.$store.commit('produccion/geolocalizacion/setHoras', value)
            }
        },
        arrTiendas(){
            return this.$store.getters['produccion/mapa/getTiendasNombre']
        },
        id_cedis(){
            return this.$store.getters['cedis/id_cedis']
        }
    },
    watch: {
        async id_cedis(){
            if (this.id_cedis != null){
                await this.$store.dispatch('produccion/mapa/getTiendasNombre', this.id_cedis)
                this.geoLimpiar()
            }
        },
    },
    async mounted(){
        await this.$store.dispatch('produccion/mapa/getTiendasNombre', this.id_cedis)
    },
    methods: {
        ...mapMutations({
            geoLimpiar: 'produccion/geolocalizacion/limpiar',
        }),
        ...mapActions({
            geoGenerar: 'produccion/geolocalizacion/generar',
        })
    }
}
</script>
<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.border-general2{
    border: 1px solid var(--text-general);
}
a{
    background-color: #ffffff;
}
.active{
    background-color: var(--text-general);
    color: #ffffff !important;
    :hover{
        color: #ffffff !important;
    }
}
/* a:hover{
    color: var(--text-general) !important;
} */
</style>