<template>
    <section class="pt-3">
        <div class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general :data="dataTable" :columnas="dataColumns" class-header="text-general f-16" :activar-seleccion="true" alto="calc(100vh - 310px)" @seleccion="push">
                    <template slot="cabecera-izquierda">
                        <p class="text-general pl-3 f-18 f-600">Listado de {{ $config.vendedor }} Inactivos</p>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        prop="favorite"
                        width="60"
                        class-name="text-center"
                        >
                            <template slot-scope="{row}">
                                <estrellas :row="row" />
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        label="Motivo de inactividad"
                        width="200"
                        class-name="f-16 text-center"
                        >
                            <template slot-scope="{row}">
                                <div class="d-flex justify-center text-general">
                                    {{ row.estado_motivo ? row.estado_motivo.nombre : 'No registra' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Fecha"
                        width="100"
                        class-name="f-16 text-center"
                        >
                            <template slot-scope="{row}">
                                <div class="d-flex justify-center text-general f-14">
                                    {{ row.estado_motivo ? row.estado_motivo.fecha : 'No registra' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Cedis"
                        width="180"
                        class-name="f-16 text-center text-general"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general">
                                    {{ row.nombre_cedis ? row.nombre_cedis : 'No registra' }}
                                </p>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
    </section>
</template>

<script>
import Produccion from "../../../services/tiendas/produccion";
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            dataTable: [],
            dataColumns: [
                {
                    valor: 'propietario_nombre',
                    titulo: 'Leechero',
                    class: "f-16 text-center text-general"
                },
                {
                    valor: 'ciudad',
                    titulo: 'Ciudad',
                    class: "f-16 text-center text-general"
                },
                {
                    valor: 'telefono',
                    titulo: 'Teléfono',
                    class: "f-16 text-center text-general"
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        async id_cedis(){
            if (this.id_cedis != null){
                this.get_inactivos()
            }
        }
    },
    mounted(){
        if (this.id_cedis != null){
            this.get_inactivos()
        }
        this.dataColumns[0].titulo = `${this.$config.vendedor}`
    },
    methods: {
        async get_inactivos(){
            try {
                let params =  { id_cedis: this.id_cedis }
                const { data } = await Produccion.get_inactivos(params)
                this.dataTable = data.tiendas
            } catch (error){
                this.error_catch(error)
            }
        },
        push(row){
            const ruta = this.$usuario.rol === 1 ? 'admin.tiendas.inactivos.informacion' : 'admin-vip.tiendas.inactivos.informacion'
            this.$router.push({ name: ruta, params :{ id_tienda: row.id } })
        }
    }
}
</script>
