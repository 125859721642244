import axios from "axios"

const API = "tienda/cambio-datos";

const cambioDatos = {
    getDatos(params){
        return axios(`${API}/get-datos`,{ params })
    },
    updateDatos(params){
        return axios.post(`${API}/update-datos`, params)
    },
    updateCedis(id_tienda,model){
        return axios.put(`${API}/${id_tienda}/update-cedis`, model)
    },
    updateCondicion(id_tienda,model){
        return axios.put(`${API}/${id_tienda}/update-condicion`, model)
    },
    updateClientePedido(params){
        return axios.put(`${API}/update-cliente-pedido`,params);
    },
    updatePrecioSugerido(id_tienda){
        return axios.put(`${API}/${id_tienda}/update-precio-sugerido`);
    },
    updateEntregaDirecta(id_tienda){
        return axios.put(`${API}/${id_tienda}/update-entrega-directa`);
    },
    updateManejoInventario(params){
        return axios.put(`${API}/update-manejo-inventario`,params);
    },
    updateCambioTipoPedido(id_tienda){
        return axios.put(`${API}/${id_tienda}/update-cambio-tipo-pedido`);
    },
    updateCambioCondiciones(id_tienda){
        return axios.put(`${API}/${id_tienda}/update-cambio-condiciones`);
    },
    updateVentasSoloApp(id_tienda){
        return axios.put(`${API}/${id_tienda}/update-ventas-solo-app`);
    },
    postFranjas(model){
        return axios.post(`${API}/franjas/save`, model);
    },
    defaultFranjas(idTienda){
        return axios.delete(`${API}/${idTienda}/franjas/delete`)
    },
    putTiendasParametros(idTienda, model){
        return axios.put(`${API}/${idTienda}/parametros`, model)
    },
    putTiendaHorario(idTienda, model){
        return axios.put(`${API}/${idTienda}/parametros/horarios`, model)
    },
    /**
     * Charlie
     */
    putParametroDescuentosLista(idTienda, payload){
        return axios.put(`${API}/${idTienda}/parametros/descuentos`, payload)
    },
    updateCompras: (id_tienda, data) => axios.put(`${API}/${id_tienda}/update-compras`, data)
}

export default cambioDatos
