<template>
    <section
    class="pt-3 position-relative custom-scroll overflow-auto "
    :class="$route.name == 'admin.tiendas.produccion.abastecimiento' ? 'height-ver-leechero' : 'height-otro'"
    >
        <timercomponent v-if="cargandoTerminado" v-model="tipo_fecha" @refresh="montar()" />
        <template>
            <div class="row mx-3 align-items-center bg-white br-12 py-3 px-3">
                <div class="col-2 px-0 d-flex align-items-start py-2">
                    <p class="mx-1 f-18 f-500 text-general">Inventario <br /> del {{ $config.vendedor }} </p>
                </div>
                <div class="col border-left text-general">
                    <p class="text-center f-30">
                        {{ agregarSeparadoresNumero(cedis_valores.productos) }}
                    </p>
                    <p class="d-middle-center">
                        <i class="icon-package-variant-closed text-general f-22" />
                        <span class="f-600 f-14 text-general">  Productos </span>
                    </p>
                </div>
                <div class="col text-general border-left">
                    <p class="text-center f-30">
                        {{ agregarSeparadoresNumero(cedis_valores.unidades) }}
                    </p>
                    <div class="d-middle-center">
                        <i class="icon-format-list-checks text-general f-22" />
                        <span class="f-600 f-14 text-general">  Unidades </span>
                    </div>
                </div>
                <div class="col text-general border-left">
                    <p class="text-center f-30">
                        {{ separadorNumero(cedis_valores.valores) }}
                    </p>
                    <div class="d-middle-center">
                        <i class="icon-cash text-general f-17 mr-3" />
                        <span class="f-600 text-general"> Valor del stock  </span>
                    </div>
                </div>
            </div>
        </template>

        <template>
            <div class="row mx-3 mt-3 px-5 f-18 f-600 bg-white br-t-12 py-3 text-general">
                Relación de ventas con {{ $config.cliente + 's' }}
            </div>
            <div class="row mx-3 px-4 mb-3 position-relative bg-white br-b-12 py-2">
                <!-- Filtro -->
                <cargando v-if="cargandoComparativos" />

                <div class="px-3 m-3">
                    <div class="row cr-pointer" :class="filtro_relacion==4?'text-general':''" @click="filtro_relacion = 4">
                        <div class="col text-center px-0">
                            <p class="p-2">Hoy</p>
                        </div>
                        <div class="pt-1 w-100" :class="filtro_relacion==4?'bg-general3':'bg-grr-red'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==1?'text-general':''" @click="filtro_relacion = 1">
                        <div class="col text-center px-0">
                            <p class="p-2">Última semana</p>
                        </div>
                        <div class="pt-1 w-100" :class="filtro_relacion==1?'bg-general3':'bg-grr-red'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==2?'text-general':''" @click="filtro_relacion = 2">
                        <div class="col text-center px-0">
                            <p class="p-2">Último mes</p>
                        </div>
                        <div class="pt-1 w-100" :class="filtro_relacion==2?'bg-general3':'bg-grr-red'" />
                    </div>
                </div>
                <!-- Filtro -->
                <!-- Relaciones x filtro -->
                <div v-for="(rel, idx) in relaciones" :key="idx" class="mx-2 my-1 px-0" :class="idx>1 && rel.ids.length > 0 ? 'cr-pointer' : ''" @click="modalRelacion(idx,rel.ids,rel.nombre)">
                    <div
                    class="br-12 p-2 text-center px-4 bg-light-f5 text-general"
                    :class="{'border':( rel.porcentaje == 0 )}"
                    style="width:232px;height:42px;background-color:#F6F9FB;"
                    >
                        {{ rel.nombre }}
                    </div>
                    <div
                    class="row mx-0 my-1 px-2 justify-center"
                    :class="{ 'text-general':(rel.porcentaje>0), 'text-danger':(rel.porcentaje<0), '':(rel.porcentaje==0) }"
                    >
                        <i :class="{ 'icon-up':(rel.porcentaje>0), 'icon-down':(rel.porcentaje<0), '':(rel.porcentaje==0) }" />
                        <span>{{ rel.porcentaje>0?'+':'' }}{{ rel.porcentaje }}%</span>
                    </div>
                    <div class="row mx-0 my-1 f-22 px-2 text-muted justify-center">
                        {{ idx == 1 || idx == 2? separadorNumero(rel.valor) : agregarSeparadoresNumero(rel.valor) }}
                    </div>
                </div>
                <!-- Relaciones x filtro -->
            </div>
            <!-- Creicimiento en los ultimos xx -->
            <!-- Graficas -->
            <graficas ref="grafica" :tipo-fecha="tipo_fecha" />
            <!-- Graficas -->
            <!-- Creicimiento en los ultimos xx -->
            <div class="row mx-0 px-3 mt-5 mb-3 justify-content-start align-items-center">
                <div class="col-auto d-middle text-general f-17 f-600">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small" class="br-6">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100 br-6"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        unlink-panels
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="cr-pointer text-white d-middle-center px-3 f-14 br-6 bg-general" style="height:32px;" @click="informes">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoMetricas" mini />
            </div>
            <div class="row mx-3 justify-content-xl-around my-2 py-2 pb-4 bg-white br-12">
                <div class="col-12 text-general f-17 f-600 mt-2 mb-4">
                    Relación de ventas con clientes
                </div>
                <div class="col-auto px-0 mx-1 text-center">
                    <div class="shadow px-1 py-2 br-12 text-general mx-2 mr-3" style="background-color:#F6F9FB;width:200px;">
                        Cantidad de pedidos
                    </div>
                    <span class="f-25 text-general2">
                        {{ agregarSeparadoresNumero(comparativas.cantidad) }}
                    </span>
                </div>
                <div class="col-auto px-0 mx-1 text-center">
                    <div class="shadow px-1 py-2 br-12 text-general mx-2 mr-3" style="background-color:#F6F9FB;width:200px;">
                        Ticket promedio
                    </div>
                    <span class="f-25 text-general2">
                        {{ separadorNumero(comparativas.promedio) }}
                    </span>
                </div>
                <div class="col-auto px-0 mx-1 text-center">
                    <div class="shadow px-1 py-2 br-12 text-general mx-2 mr-3" style="background-color:#F6F9FB;width:200px;">
                        Valor total
                    </div>
                    <span class="f-25 text-general2">
                        {{ separadorNumero(comparativas.total) }}
                    </span>
                </div>
            </div>
            <div class="row mx-3 mt-3 py-3 bg-white br-t-12">
                <div class="col-auto px-5 text-general f-18 f-600">
                    Compra promedio hora/semana
                </div>
                <div class="col-12 d-flex pt-2">
                    <div class="col-2" />
                    <div class="col">
                        <div class="row mx-0 justify-tipo-s1rt border-bottom">
                            <div
                            v-for="(data, i) in tipos"
                            :key="i"
                            class="col-auto cr-pointer p-2 f-14"
                            :class="tipo == data.id ? 'border-b-red text-general-red' : 'text-muted2' "
                            @click=" tipo = data.id"
                            >
                                {{ data.mes }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Chart Promedio -->
            <chartBarras :datos="datos_promedio" :chart="chart_promedio" class="br-b-12" />
            <!-- Chart Promedio -->
            <div class="row f-18 f-600 px-3 mx-3 mt-3 bg-white br-t-12 py-3 text-general align-items-center">
                Productos más comprados por dinero
                <div class="bg-light-f5 f-500 br-20 text-general2 px-2 ml-auto">
                    <i class="icon-package-variant text-general2 f-20" />
                    Stock actual
                </div>
            </div>
            <div class="row mx-3 px-5 py-3 bg-white br-b-12" :class="!productos_card_total.length ? 'justify-center' : ''">
                <template v-if="productos_card_total.length">
                    <div v-for="(producto,d) in productos_card_total" :key="d" class="col-auto position-relative">
                        <el-tooltip v-if="producto.estado == 0" placement="bottom" content="Inactivo" effect="light">
                            <div class="position-absolute z-1 rounded-circle bg-dark d-middle-center" style="right:20px;width:40px;height:40px;">
                                <i class="icon-eye-off f-25 text-white" />
                            </div>
                        </el-tooltip>
                        <div class="position-absolute z-1 bg-light-f5 f-15 f-500 br-20 text-general2 ml-auto px-2 py-1" style="right:31px;top:-16px;">
                            <i class="icon-package-variant text-general2 f-15" />{{ producto.stock_actual }}
                        </div>
                        <el-tooltip placement="bottom" :content="`${producto.sku} - ${producto.nombre} - ${producto.presentacion}`" effect="light">
                            <div class="border br-12 d-middle-center" style="width:115px;height:115px;object-fit:cover;">
                                <img class="w-50" :src="producto.foto_firmada" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="mb-0 text-general2 f-15 text-center">
                            {{ separadorNumero(producto.total) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(producto.porcentaje_total,1) }}%
                        </p>
                    </div>
                </template>
                <!-- Sin datos -->
                <div v-else class="col">
                    <div class="row justify-center">
                        <div class="borde text-center p-2 br-12 sin_datos">
                            <img src="/img/no-imagen/mercado.svg" alt="" />
                        </div>
                    </div>
                    <div class="row mx-0 justify-center f-15 mt-2 text-general">
                        Aun no se han registrado ventas
                    </div>
                </div>
                <!-- Sin datos -->
                <div v-if="productos_ver_mas_total.total > 0" class="col-auto ml-auto">
                    <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirProductos(1)">
                        <p class="mb-0 text-white">
                            Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                        </p>
                    </div>
                    <p class="mb-0 text-general2 f-15 text-center">
                        {{ separadorNumero(productos_ver_mas_total.total) }}
                    </p>
                    <p class="mb-0 text-general f-600 f-18 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_total.porcentaje,1) }}%
                    </p>
                </div>
            </div>
            <div class="row mx-3 f-18 px-3 f-600 bg-white br-t-12 mt-3 py-3 text-general">
                Productos más comprados por número de pedidos
                <div class="bg-light-f5 f-500 br-20 text-general2 px-2 ml-auto">
                    <i class="icon-package-variant text-general2 f-20" />
                    Stock actual
                </div>
            </div>
            <div class="row mx-3 px-3 py-3 pb-4 bg-white br-b-12 mb-3" :class="!productos_card_cantidad.length ? 'justify-center' : ''">
                <template v-if="productos_card_cantidad.length">
                    <div v-for="(producto,d) in productos_card_cantidad" :key="d" class="col-auto position-relative">
                        <el-tooltip v-if="producto.estado == 0" placement="bottom" content="Inactivo" effect="light">
                            <div class="position-absolute z-1 rounded-circle bg-dark d-middle-center" style="right:20px;width:40px;height:40px;">
                                <i class="icon-eye-off f-25 text-white" />
                            </div>
                        </el-tooltip>
                        <div class="position-absolute z-1 bg-light-f5 f-15 f-500 br-20 text-general2 ml-auto px-2 py-1" style="right:31px;top:-16px;">
                            <i class="icon-package-variant text-general2 f-15" />{{ producto.stock_actual }}
                        </div>
                        <el-tooltip placement="bottom" :content="`${producto.sku} - ${producto.nombre} - ${producto.presentacion}`" effect="light">
                            <div class="border br-12 d-middle-center" style="width:115px;height:115px;object-fit:cover;">
                                <img class="w-50" :src="producto.foto_firmada" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="mb-0 text-general2 f-15 text-center">
                            {{ agregarSeparadoresNumero(producto.cantidad) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(producto.porcentaje_cantidad,1) }}%
                        </p>
                    </div>
                </template>
                <!-- Sin datos -->
                <div v-else class="col">
                    <div class="row justify-center">
                        <div class="borde text-center p-2 br-12 sin_datos">
                            <img src="/img/no-imagen/mercado.svg" alt="" />
                        </div>
                    </div>
                    <div class="row mx-0 mt-3 justify-center f-15 text-general">
                        Aun no se han registrado ventas
                    </div>
                </div>
                <!-- Sin datos -->
                <div v-if="productos_ver_mas_cantidad.cantidad > 0" class="col-auto ml-auto">
                    <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirProductos(2)">
                        <p class="mb-0 text-white">
                            Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                        </p>
                    </div>
                    <p class="mb-0 text-general2 f-15 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.cantidad) }}
                    </p>
                    <p class="mb-0 text-general f-600 f-18 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.porcentaje,1) }}%
                    </p>
                </div>
            </div>
            <div class="row mx-3 px-3 py-3 bg-white br-12">
                <div class="col-12 px-0">
                    <p class="text-general f-18 f-600">
                        Categorías más compradas
                    </p>
                </div>
                <div class="col px-5 py-3 mb-5">
                    <div class="row mx-0">
                        <div v-for="(data, d) in categorias_dinero.slice(0,8)" :key="d" class="col-auto px-2">
                            <div class="br-12 border d-middle-center px-3 f-12" style="width:125px;height:75px;background-color:#F8F9FF;">
                                {{ data.nombre }}
                            </div>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.total) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.porcentaje_total,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="categorias_dinero.length > 8" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-2 cr-pointer"
                    @click="verCategorias('Categorias mas vendidas')"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <!-- Compras por genero -->
            <div class="row mx-3 px-3 mt-3 py-3 bg-white br-t-12 text-general f-600 f-18">
                Pedidos y entregas directas
            </div>
            <div class="row mx-3 py-3 px-3 bg-white text-general">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template>
                            <el-tooltip placement="top" :content="`${directos[0].nombre}: ${agregarSeparadoresNumero(directos[0].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${directos[0].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${directos[1].nombre}: ${agregarSeparadoresNumero(directos[1].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-muted br-tr-5 br-br-5" :style="`width:${directos[1].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${directos[2].nombre}: ${agregarSeparadoresNumero(directos[2].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-gris" :style="`width:${directos[2].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${directos[3].nombre}: ${agregarSeparadoresNumero(directos[3].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-light-f5 br-tr-5 br-br-5" :style="`width:${directos[3].porcentaje}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-3 bg-white br-b-12 py-3 justify-content-center">
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#000000" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[0].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[0].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[0].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#6D6B6B" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[1].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[1].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[1].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#DBDBDB" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[2].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[2].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[2].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#f5f5f5" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[3].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[3].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[3].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[3].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-3 px-3 mt-4 py-3 bg-white br-t-12 text-general f-600 f-18">
                Compradores por dispositivo
            </div>
            <div class="row mx-3 bg-white py-3">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template v-if="datos == true">
                            <el-tooltip placement="top" :content="`${dispositivos[1].nombre}: ${agregarSeparadoresNumero(dispositivos[1].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${dispositivos[1].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${dispositivos[2].nombre}: ${agregarSeparadoresNumero(dispositivos[2].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general2" :style="`width:${dispositivos[2].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${dispositivos[0].nombre}: ${agregarSeparadoresNumero(dispositivos[0].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-gris br-tr-5 br-br-5" :style="`width:${dispositivos[0].porcentaje}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-3 mb-3 justify-content-center py-3 bg-white br-b-12">
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/android.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Android {{ agregarSeparadoresNumero(dispositivos[1].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[1].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/apple.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                iOS {{ agregarSeparadoresNumero(dispositivos[2].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[2].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/world-wide-web.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Web {{ agregarSeparadoresNumero(dispositivos[0].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[0].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- Modales -->
        <modal-ver-categorias ref="modalVerCategorias" />
        <modal-productos ref="productos" />
        <!-- Modales -->
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import echarts from 'echarts'
import moment from 'moment'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
import MetricasLeecheroAdmin from "~/services/metricas_leechero_admin"
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];
const fecha_timer = moment()


export default {
    components: {
        modalProductos: () => import ('./partials/modalProductos'),
        graficas: () => import ('./graficas'),
    },
    data(){
        return{
            cargandoComparativos: true,
            cargandoCrecimiento: true,
            cargandoMetricas: true,
            loading: false,
            tipo_fecha: 1,
            filtro_relacion: 1,
            tipo_relacion: 1,
            relaciones: [
                { nombre: 'Cantidad de pedidos', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Total ventas', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Ticket promedio', porcentaje: 0, valor: 0, ids: [] },
            ],
            datos: true,
            datos_promedio: [
                { nombre: 'Hora de mayor venta promedio', valor: 0, fecha: 'Ene. 2020', color: 'bg-gr-general', formato:1 },
                { nombre: 'Hora de menor venta promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-pink', formato:1 },
                { nombre: 'Hora de mayor # pedidos promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-red', formato:2 },
                { nombre: 'Hora de menor # pedidos promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-red', formato:2 }
            ],
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 3,
            tipo: 1,
            tipos: [
                { mes: 'Cada Hora', id: 1 },
                { mes: 'Cada día', id: 2 }
            ],
            chart_promedio: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['alias', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },
            dias: [],
            horas: [],
            min_dias_cantidad: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_dias_cantidad: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_dias_total: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_dias_total: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_horas_cantidad: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_horas_cantidad: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_horas_total: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_horas_total: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            categorias_dinero: [],
            productos_card_total: [],
            productos_ver_mas_total: {
                total: 0,
                porcentaje: 0
            },
            productos_card_cantidad: [],
            productos_ver_mas_cantidad: {
                total: 0,
                porcentaje: 0
            },
            clicksPedidos: 1,
            clientes: [],
            clientes_paginado: [],
            total_clientes: 0,
            total_clientes_paginado: 0,
            total_por_pagina: 10,
            semana:{},
            ultimo_mes:{},
            ultimo_trimestre:{},
            hoy:{},
            dispositivos: [
                {
                    nombre: "Android",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "iOS",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Web",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            directos: [
                {
                    nombre: "Leecheros con la misma dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Leecheros con diferente dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Clientes con la misma dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Clientes con diferente dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            cedis_valores: {
                productos: null,
                unidades: null,
                valores: null
            },
            interval: null,
            fechaInforme: '',
            metricas_created: null,
            metricas_entregas: null,
            clientes_informe_created: null,
            clientes_informe_entregas: null,
            crecimiento_entregas: null,
            crecimiento_created: null,
            semana_created: null,
            ultimo_mes_created: null,
            ultimo_trimestre_created: null,
            hoy_created: null,
            semana_entregas: null,
            ultimo_mes_entregas: null,
            ultimo_trimestre_entregas: null,
            hoy_entregas: null,
            primeraVez: true,
            comparativas:{
                cantidad: 0,
                promedio: 0,
                leecheros: 0,
                total: 0,
            },
            order: true,
            user_leechero: this.$route.params.id_tienda
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        cargandoTerminado(){
            return  !this.cargandoComparativos &&
                    !this.cargandoCrecimiento &&
                    !this.cargandoMetricas &&
                    (this.$refs.grafica && !this.$refs.grafica.loading1) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading2) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading3)
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        tipo(){
            this.montarSemanaHoras()
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
            this.$refs.grafica.cargando()
        },
        filtro_relacion(){
            this.calcularComparativos()
        },
        tipo_fecha(){
            this.calcularMetricasInformes()
            this.calcularInforme()
            this.calcularCrecimiento()
            this.calcularComparativos()
        },
        cargandoTerminado(val){
            this.$store.commit('cedis/setCargandoTerminado', val)
        }
    },
    mounted(){
        this.$store.commit('cedis/setCargandoTerminado', false)

        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoComparativos = true
                this.cargandoCrecimiento = true
            }
            this.cargandoMetricas = true
        },
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.comparativos()
                        await this.inventarioLeechero()
                        await this.$refs.grafica.montar()
                        await this.metricas_informe()
                    }, 1000);
                }else{
                    await this.comparativos()
                    await this.inventarioLeechero()
                    await this.$refs.grafica.montar()
                    await this.metricas_informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        modalRelacion(idx,ids,titulo){
            if (idx>1 && ids.length > 0){
                let filtro = ''
                switch (this.filtro_relacion){
                case 1:
                    filtro = "Durante última semana"
                    break;
                case 2:
                    filtro = "Durante último mes"
                    break;
                case 3:
                    filtro = "Durante último trimestre"
                    break;
                case 4:
                    filtro = "Durante último día"
                    break;
                default:
                    break;
                }
                this.$refs.modalDetalleRelacion.toggle(ids,titulo,filtro)
            }
        },
        AbrirProductos(tipo){
            let params = {
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                tipo,
                user_leechero: this.user_leechero,
                id_moneda: this.id_moneda,
                tipo_fecha: this.tipo_fecha,
            }
            this.$refs.productos.toggle(params)
        },
        async metricas_informe(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    user_leechero: this.user_leechero
                }

                const { data } = await MetricasLeecheroAdmin.metricas_informe(params)

                this.metricas_created = data.data.pedidos_created
                this.metricas_entregas = data.data.pedidos_entregas

                this.calcularMetricasInformes()


            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoMetricas = false
            }
        },
        calcularMetricasInformes(){
            try {

                let datos = this.tipo_fecha == 1 ? this.metricas_entregas : this.metricas_created

                this.dias = datos.dias
                this.horas = datos.horas
                this.max_dias_cantidad = datos.max_dias_cantidad
                this.min_dias_cantidad = datos.min_dias_cantidad
                this.max_dias_total = datos.max_dias_total
                this.min_dias_total = datos.min_dias_total
                this.max_horas_cantidad = datos.max_horas_cantidad
                this.min_horas_cantidad = datos.min_horas_cantidad
                this.max_horas_total = datos.max_horas_total
                this.min_horas_total = datos.min_horas_total

                this.montarSemanaHoras()

                datos.productos_card_total.map(p=>{
                    p.stock_actual = this.agregarSeparadoresNumero(p.stock_actual)
                })
                this.productos_card_total = datos.productos_card_total
                this.productos_ver_mas_total = datos.productos_ver_mas_total

                datos.productos_card_cantidad.map(p=>{
                    p.stock_actual = this.agregarSeparadoresNumero(p.stock_actual)
                })
                this.productos_card_cantidad = datos.productos_card_cantidad
                this.productos_ver_mas_cantidad = datos.productos_ver_mas_cantidad

                //generos
                this.directos = datos.directos
                this.directos[0].nombre = `${this.$config.vendedor} con la misma direccíon`;
                this.directos[1].nombre = `${this.$config.vendedor} con diferente direccíon`;
                this.directos[2].nombre = `${this.$config.cliente} con la misma dirección`;
                this.directos[3].nombre = `${this.$config.cliente} con diferente dirección`;
                this.categorias_dinero = datos.categorias

                this.dispositivos = datos.dispositivos
                //edades
                this.comparativas = datos.comparativas
            } catch (error){
                console.log(error);
            }
        },
        montarSemanaHoras(){
            try {
                if (this.tipo == 1){
                    this.chart_promedio.dataset.source = this.horas
                    this.datos_promedio[0].nombre = 'Hora de mayor venta promedio'
                    this.datos_promedio[0].valor = this.max_horas_total ? this.max_horas_total.total : 0
                    this.datos_promedio[0].fecha = this.max_horas_total ? this.max_horas_total.alias : 'No registra'
                    this.datos_promedio[1].nombre = 'Hora de menor venta promedio'
                    this.datos_promedio[1].valor = this.min_horas_total ? this.min_horas_total.total : 0
                    this.datos_promedio[1].fecha = this.min_horas_total ? this.min_horas_total.alias : 'No registra'
                    this.datos_promedio[2].nombre = 'Hora de mayor # pedidos promedio'
                    this.datos_promedio[2].valor = this.max_horas_cantidad ? this.max_horas_cantidad.cantidad : 0
                    this.datos_promedio[2].fecha = this.max_horas_cantidad ? this.max_horas_cantidad.alias : 'No registra'
                    this.datos_promedio[3].nombre = 'Hora de menor # pedidos promedio'
                    this.datos_promedio[3].valor = this.min_horas_cantidad ? this.min_horas_cantidad.cantidad :  0
                    this.datos_promedio[3].fecha = this.min_horas_cantidad ? this.min_horas_cantidad.alias :  'No registra'
                } else {
                    this.chart_promedio.dataset.source = this.dias
                    this.datos_promedio[0].nombre = 'Día de mayor venta promedio'
                    this.datos_promedio[0].valor = this.max_dias_total ? this.max_dias_total.total : 0
                    this.datos_promedio[0].fecha = this.max_dias_total ? this.max_dias_total.alias : 'No registra'
                    this.datos_promedio[1].nombre = 'Día de menor venta promedio'
                    this.datos_promedio[1].valor = this.min_dias_total ? this.min_dias_total.total : 0
                    this.datos_promedio[1].fecha = this.min_dias_total ? this.min_dias_total.alias : 'No registra'
                    this.datos_promedio[2].nombre = 'Día de mayor # pedidos promedio'
                    this.datos_promedio[2].valor = this.max_dias_cantidad ? this.max_dias_cantidad.cantidad : 0
                    this.datos_promedio[2].fecha = this.max_dias_cantidad ? this.max_dias_cantidad.alias : 'No registra'
                    this.datos_promedio[3].nombre = 'Día de menor # pedidos promedio'
                    this.datos_promedio[3].valor = this.min_dias_cantidad ? this.min_dias_cantidad.cantidad : 0
                    this.datos_promedio[3].fecha = this.min_dias_cantidad ? this.min_dias_cantidad.alias : 'No registra'
                }
            } catch (error){
                console.log(error);
            }
        },
        async inventarioLeechero(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    user_leechero: this.user_leechero
                }
                const { data } = await MetricasLeecheroAdmin.inventarioLeechero(params)

                this.cedis_valores = data.data.info_cedis

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoCrecimiento = false
            }
        },
        async comparativos(){
            try {
                let params =  {
                    id_moneda: this.id_moneda,
                    user_leechero: this.user_leechero
                }



                const { data } = await MetricasLeecheroAdmin.comparativos(params)
                this.$validar(data)

                this.semana_created = data.data.semana.pedidos_created
                this.ultimo_mes_created = data.data.ultimo_mes.pedidos_created
                this.hoy_created = data.data.hoy.pedidos_created
                this.semana_entregas = data.data.semana.pedidos_entregas
                this.ultimo_mes_entregas = data.data.ultimo_mes.pedidos_entregas
                this.hoy_entregas = data.data.hoy.pedidos_entregas

                this.calcularComparativos()

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoComparativos = false
            }
        },
        calcularComparativos(){
            try {

                this.semana = this.tipo_fecha == 1 ? this.semana_entregas : this.semana_created
                this.ultimo_mes = this.tipo_fecha == 1 ? this.ultimo_mes_entregas : this.ultimo_mes_created
                this.hoy = this.tipo_fecha == 1 ? this.hoy_entregas : this.hoy_created

                let tipo = {}
                switch (this.filtro_relacion){
                case 1:
                    tipo = this.semana
                    break;
                case 2:
                    tipo = this.ultimo_mes
                    break;
                case 4:
                    tipo = this.hoy
                    break;
                default:
                    break;
                }

                this.relaciones[0].porcentaje = tipo.pedidos.porcentaje
                this.relaciones[0].valor = tipo.pedidos.valor
                this.relaciones[1].porcentaje = tipo.ventas.porcentaje
                this.relaciones[1].valor = tipo.ventas.valor
                this.relaciones[2].porcentaje = tipo.promedio.porcentaje
                this.relaciones[2].valor = tipo.promedio.valor
            } catch (error){
                console.log(error)
            }
        },
        async informes(){
            try {
                this.tipo = 1
                this.cargando(false)

                await this.metricas_informe()

            } catch (error){
                console.log(error)
            }
        },
        verCategorias(titulo){
            this.$refs.modalVerCategorias.toggle({ categorias: this.categorias_dinero, titulo});
        },
    }
}
</script>
<style lang="scss" >
.text-blue{
    color: #0892DD;
}
.bg-blue{
    background-color: #0892DD;
}
.br-12{
    border-radius: 12px;
}
.border-bottom-filtro{
        border-bottom: 4px solid #FF9D32;
        border-radius: 1px;
    }
.bg-orange{
    background-color: #FF9539;
}
.text-orange{
    color: #FF9539;
}
.bg-orange-clear{
    background-color: #FFDF75;
}
.text-orange-clear_2{ color: #FFB87A;}
.text-orange-clear{ color: #FFDF75; }
.text-green{ color: #02D6BC;}
.text-dark-blue{ color: #284367;}
.text-blue-light{ color: #0892DD ;}
.text-pink-light{ color:#FFB1D0; }
.text-dark-green{ color: #03BB83;}
.text-blue{ color: #6BD1FF;}
.text-dark-pink{ color:#E33FC3;}

.bg-orange-clear_2{ background-color: #FFB87A;}
.bg-orange-clear{ background-color: #FFDF75; }
.bg-green{ background-color: #02D6BC;}
.bg-dark-blue{ background-color: #284367;}
.bg-blue-light{ background-color: #0892DD ;}
.bg-pink-light{ background-color:#FFB1D0; }
.bg-dark-green{ background-color: #03BB83;}
.bg-blue{ background-color: #6BD1FF;}
.bg-dark-pink{ background-color:#E33FC3;}


.bg-blue-clear{background-color: #03D6BC;}
.bg-blue-dark{ background-color: #284367;}
.bg-dark{ background-color: #333435;}
.text-dark{ color: #333435;}
.text-blue-clear{ color: #03D6BC; }
.bg-purple{
    background-color: #6D3CE1;
}
.text-purple{
    color: #6D3CE1;
}
.sin_datos{
    width:115px;
    height:115px;
    background-color:#f3f3f3;
}
.height-cedis{
    height: calc(100vh - 145px);
}
.height-otro{
    height: calc(100vh - 188px);
}
.height-ver-leechero{
    height: calc(100vh - 180px);
}
.active-switch{
    background-color: var(--color-general);
    color: #ffffff;
}
.switch{
    background-color: #ffffff;
    color:  var(--color-general);
}
.br-tl-12{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.br-tr-12{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.br-12{
    border-radius: 12px;
}
</style>
